import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { viewsAutomatedValueGetter } from 'components/Campaign/Influencer/View/Stats/Performance/columns/views';
import { Placement } from 'shared/types/Placement';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';

export default function vpc() {
  return {
    ...numberColDef,
    valueGetter: ({ data, context }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      const views =
        viewsAutomatedValueGetter(data) ??
        manualMetricsValueGetter(data, context, 'views');
      const conversions = manualMetricsValueGetter(
        data,
        context,
        'conversions',
      );
      if (
        !views ||
        !conversions ||
        !Number.isFinite(Number(views)) ||
        !Number.isFinite(Number(conversions))
      ) {
        return null;
      }

      return Number(views) / Number(conversions);
    },
    editable: false,
    headerName: 'VPC',
    field: 'vpc',
  };
}
