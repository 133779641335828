export const platformUrlsRegex = {
  youTube: /^(https?:\/\/)(www\.)(youtube\.com)\/channel\/[^/]*$/i,
  youTubeUsername:
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com)\/(?:channel|username|@)(?:\/)([^/]*)/i,
};

export const tikTokCreatorUrl =
  /https:\/\/(?:www\.)tiktok\.com\/@([a-z0-9_.]+)$/i;

export const instagramCreatorUrl =
  /https:\/\/(?:www\.)instagram\.com\/([a-z0-9_.]+)\/?(\?.*)?$/i;

export const youTubeCanonicalUrl = /\/(?:channel\/([^/?]*))/i;

export const benPlatformUrlsRegex = {
  youTubeByType: [
    { matchType: 'channelId', exp: youTubeCanonicalUrl },
    { matchType: 'username', exp: /\/user\/(.*)(?:[/?])/i },
    { matchType: 'username', exp: /\/user\/(.*)/i },
    { matchType: 'username', exp: /\/c\/(.*)/i },
    { matchType: 'username', exp: /@(.*)/i },
  ],
};

export const sendToBENPlatformUrlsRegex = {
  youtube: /youtube\.com\/(c|channel|user|watch|@\w*)/,
  youTubeByType: [
    { matchType: 'channelId', exp: /\/(?:channel\/([^/?]*)|@([^/?]*))/i },
    { matchType: 'username', exp: /\/user\/(.*)(?:[/?])/i },
    { matchType: 'username', exp: /\/user\/(.*)/i },
    { matchType: 'username', exp: /\/c\/(.*)/i },
  ],
  sullyGnomeChannelSearch: /sullygnome\.com\/channelsearch/,
  tikTokCreatorUrl,
};

export function youTubeRegexByType(
  url: string,
): [string | undefined | null, string | undefined | null] | [] {
  if (url.includes('tiktok.com')) {
    return [];
  }

  return benPlatformUrlsRegex.youTubeByType.reduce<
    [string | undefined | null, string | undefined | null] | []
  >((acc, { matchType, exp }) => {
    if (acc?.length) {
      return acc;
    }
    const regex = exp;
    const tmpMatch = url.match(regex);
    if (!tmpMatch?.length) {
      return acc;
    }
    return [matchType, matchType === 'username' ? tmpMatch?.[1] : null];
  }, []);
}

export const URL_REGEX_CFG = {
  twitter: {
    rgxs: [/(?:twitter\.com|x.com)\/([\w-]+)\/?/i],
    baseUrl: 'https://www.twitter.com/',
  },
  youtube: {
    rgxs: [/youtube\.com\/(?:user\/)?([\w-]+)\/?/i],
    baseUrl: 'https://www.youtube.com/user/',
  },
  instagram: {
    rgxs: [/instagram\.com\/([\w-.]+)\/?/i],
    baseUrl: 'https://www.instagram.com/',
  },
  twitch: {
    rgxs: [/twitch\.tv\/([\w-]+)\/profile\/?/i, /twitch\.tv\/([\w-]+)\/?/i],
    baseUrl: 'https://www.twitch.tv/',
  },
  facebook: {
    rgxs: [/facebook\.com\/([a-z0-9%_\-.]+)\/?/i],
    baseUrl: 'https://www.facebook.com/',
  },
  tiktok: {
    rgxs: [/tiktok\.com\/@([a-z0-9_.]+)\/?/i],
    baseUrl: 'https://www.tiktok.com/@',
  },
};

export const VALID_SOURCE_URL_REGEX_CFG = {
  twitter: {
    rgxs: [/twitter|x\.com/i],
    baseUrl: 'https://www.twitter.com/',
  },
  youtube: {
    rgxs: [/youtube\.com/i, /youtu\.be\.com/],
    baseUrl: 'https://www.youtube.com/',
  },
  instagram: {
    rgxs: [/instagram\.com/i, /drive\.google\.com/i],
    baseUrl: 'https://www.instagram.com/',
  },
  twitch: {
    rgxs: [/twitch\.tv/i],
    baseUrl: 'https://www.twitch.tv/',
  },
  facebook: {
    rgxs: [/facebook\.com/i],
    baseUrl: 'https://www.facebook.com/',
  },
  tiktok: {
    rgxs: [/tiktok\.com/i],
    baseUrl: 'https://www.tiktok.com/',
  },
};

export const tikTokVideoUrlRegex =
  /^https:\/\/(?:www\.)tiktok\.com\/@.*\/video\/[a-zA-Z0-9]*$/;

export function getTikTokHandleNameFromUrl(url?: string | null) {
  if (!url) {
    return null;
  }
  const match = url.match(
    /https:\/\/(?:www\.)tiktok\.com\/@(.*)\/video\/[a-zA-Z0-9]*/,
  );

  return match?.[1] ?? null;
}

export function getTikTokVideoIdFromUrl(url?: string | null) {
  if (!url) {
    return null;
  }
  const match =
    /https:\/\/(?:www\.)tiktok\.com\/@.*\/video\/([a-zA-Z0-9]*)/.exec(url);
  return match?.[1] ?? null;
}

export function getYouTubeVideoIdFromUrl(url?: string | null) {
  if (!url) {
    return null;
  }
  const match =
    /(?:https?:\/\/(?:www\.)?)(?:(?:youtube.com\/shorts\/|youtube.com\/watch\?v=|youtu\.be\/))(\w*)(?:.*)/.exec(
      url,
    );
  return match?.[1] ?? null;
}

export function getInstagramShortcodeFromUrl(url?: string | null) {
  if (!url) {
    return null;
  }
  const match =
    /(?:https?:\/\/)?(?:www.)?instagram.com\/?([\w\-.]+)?\/(p|reel|tv)?(stories\/[\w\-.]+)?\/([\w\-.]+)\/?/.exec(
      url,
    );

  return match?.[4] ?? null;
}

export function isShortTikTokURL(url?: string | null) {
  if (!url) {
    return null;
  }

  const regex = /^https?:\/\/vm\.tiktok\.com\/[\w-]+\/?$/;
  return regex.test(url);
}
