import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { Placement } from 'shared/types/Placement';
import currencyColDef from 'components/DataGrid/Destination/colDef/currencyColDef';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';
import { getPostOrTotalFee } from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/postFee';
import { viewsAutomatedValueGetter } from 'components/Campaign/Influencer/View/Stats/Performance/columns/views';
import currency from 'components/DataGrid/formatters/currency';

export default function cpv() {
  return {
    ...currencyColDef,
    valueFormatter: ({ value }: { value: number }) => currency({ value }, 3),
    valueGetter: ({ data, context }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }
      const views =
        viewsAutomatedValueGetter(data) ??
        manualMetricsValueGetter(data, context, 'views');
      const cogs = getPostOrTotalFee(data);

      if (
        !views ||
        !cogs ||
        !Number.isFinite(Number(views)) ||
        !Number.isFinite(Number(cogs))
      ) {
        return null;
      }

      return Number(cogs) / Number(views);
    },
    editable: false,
    headerName: 'CPV',
    field: 'cpv',
  };
}
