import classnames from 'classnames';

import './Row.scss';

const cbn = 'ui-grid-row';

export default function UIGridRow({
  children = null,
  className = null,
  hasOffset = false,
}) {
  return (
    <div
      className={classnames(cbn, className, {
        [`${cbn}--has-offset`]: hasOffset,
      })}
    >
      {children}
    </div>
  );
}
