import CampaignInfo from 'components/Tiktok/shared/CampaignInfo/CampaignInfo';
import CreatorInfo from 'components/Tiktok/shared/CreatorInfo/CreatorInfo';
import Loader from 'components/UI/Loader/Loader';
import React from 'react';
import { Placement } from 'shared/types/Placement';
import { TCMCreatorMediaInsights } from 'shared/types/Production';
import { Property } from 'shared/types/Property';
import { TCMSparkAdStatusData } from 'shared/types/TikTokMarketPlace';
import getMediaInsightsPostById from 'shared/utils/tikTok/getMediaInsightByVideoId';
import useUser from 'store/authentication/useUser';
import SparkAdsRecommendation from './SparkAdsRecommendation/SparkAdsRecommendation';

import CreateSparkAdd from './CreateSparkAd/CreateSparkAd';
import { Period, SetPeriod } from './CreateSparkAd/types';
import UpdateSparkAd from './UpdateSparkAd/UpdateSparkAd';

import './TiktokSparkAds.scss';

export default function TiktokSparkAds({
  placement,
  hasSparkAd,
  hasError,
  sparkAdStatusData,
  onCreateAd,
  onCloseModal,
  postUrl,
  loading,
  period,
  setPeriod,
  videoId,
  tiktokCreatorData,
  tikTokSparkAdStatusRefetch,
}: {
  placement: Placement;
  hasSparkAd: boolean;
  hasError: boolean;
  sparkAdStatusData: TCMSparkAdStatusData;
  onCreateAd: () => void;
  onCloseModal: () => void;
  postUrl: string | undefined | null;
  loading: boolean;
  period: Period | null;
  setPeriod: SetPeriod;
  videoId: string | null;
  tiktokCreatorData: {
    property: Property;
    tikTokCreatorMediaInsights: TCMCreatorMediaInsights;
  };
  tikTokSparkAdStatusRefetch: () => void;
}) {
  const { user } = useUser();
  const deliverable = placement?.integration?.deliverable;

  const thumbnailUrl = React.useMemo(
    () =>
      getMediaInsightsPostById(
        videoId ?? '',
        tiktokCreatorData?.tikTokCreatorMediaInsights,
      )?.thumbnailUrl,
    [tiktokCreatorData?.tikTokCreatorMediaInsights, videoId],
  );

  return (
    <section className="tiktok-spark-ads">
      <div className="tiktok-spark-ads__drawer-title">
        {hasSparkAd ? (
          <p>Manage Spark Ad</p>
        ) : (
          <p>Request Spark Ads Authorization</p>
        )}
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <CreatorInfo
            thumbnail={
              placement?.integration?.production?.tiktokCreatorDataInsights
                ?.publicDataInsights?.profileImage ?? ''
            }
            creatorName={tiktokCreatorData?.property?.name ?? ''}
            handleName={deliverable?.tiktokOrder?.handleName}
            drawer="spark-ads"
          />

          <CampaignInfo
            brandLogo={deliverable?.brand?.logo?.url ?? ''}
            campaignName={deliverable?.campaign?.projectName ?? ''}
            drawer="spark-ads"
          />

          {hasSparkAd ? (
            <UpdateSparkAd
              sparkAdStatusData={sparkAdStatusData}
              contentLink={postUrl}
              onResendRequest={() => onCreateAd()}
              thumbnailUrl={thumbnailUrl}
              tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
            />
          ) : (
            <CreateSparkAdd
              hasError={hasError}
              period={period}
              setPeriod={setPeriod}
              onCreateAd={onCreateAd}
              onCloseModal={onCloseModal}
              contentLink={postUrl}
              thumbnailUrl={thumbnailUrl}
            />
          )}

          {user?.role === 'admin' ? (
            <SparkAdsRecommendation videoId={videoId} />
          ) : null}
        </>
      )}
    </section>
  );
}
