import Tippy from '@tippyjs/react';
import { formatValue } from '../../utils/formatters';
import { MetricsTableRow } from '../types';

const getValue = (row: MetricsTableRow) => {
  const formattedValue = (
    <span className="simple-metrics-table__row__value">
      {formatValue(row?.value as number, row?.type)}
    </span>
  );

  return row.description ? (
    <Tippy
      className="simple-metrics-table_row__description"
      content={row.description}
      arrow={false}
      hideOnClick={false}
    >
      {formattedValue}
    </Tippy>
  ) : (
    formattedValue
  );
};

export default function MetricRow({ row }: Readonly<{ row: MetricsTableRow }>) {
  if (row?.type === 'link') {
    return (
      <div className="simple-metrics-table__row">
        <a
          className="label-link external"
          href={String(row?.value)}
          target="_blank"
          rel="noreferrer"
        >
          {row?.label}
        </a>
      </div>
    );
  }

  return (
    <div className="simple-metrics-table__row">
      <span className="simple-metrics-table__row__label">{row?.label}</span>
      {getValue(row)}
    </div>
  );
}
