import React from 'react';
import classnames from 'classnames';
import usePortal from 'components/UI/utils/usePortal';
import UIIconButton from 'components/UI/Icon/Button/Button';
import useDialog from './useDialog';
import './Dialog.scss';

const cbn = 'ui-dialog';

type UIDialogProps = {
  className?: string | null;
  description: React.ReactNode | string;
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
  overlayClassName?: string | null;
  renderActions?: (() => JSX.Element) | null;
  title: string;
};

function UIDialog({
  className = null,
  description = null,
  isOpen = false,
  onClose = () => {},
  overlayClassName = null,
  renderActions = null,
  title,
}: UIDialogProps) {
  const Portal = usePortal();

  if (!isOpen) return null;

  return (
    <Portal>
      <div className={classnames(`${cbn}__overlay`, overlayClassName)}>
        <div className={classnames(cbn, className)}>
          <UIIconButton
            className={`${cbn}__close`}
            type="close"
            onClick={onClose}
          />
          <h1 className={`${cbn}__title`}>{title}</h1>
          {description && (
            <div className={`${cbn}__description`}>{description}</div>
          )}
          {renderActions && (
            <div className={`${cbn}__actions`}>{renderActions()}</div>
          )}
        </div>
      </div>
    </Portal>
  );
}

export { useDialog };

export default UIDialog;
