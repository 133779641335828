import { Size } from '@UIComponents/Drawer/Drawer';
import TiktokSparkAdsDrawerDisplay from 'components/Tiktok/TiktokSparkAds/TiktokSparkAdsDrawer/TiktokSparkAdsDrawerDisplay/TiktokSparkAdsDrawerDisplay';
import { Placement } from 'shared/types/Placement';

export default function TiktokSparkAdsDrawer({
  isOpen,
  onRequestClose,
  placement,
  postUrl,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  postUrl: string | undefined | null;
  placement: Placement;
}) {
  if (!isOpen) {
    return null;
  }
  return (
    <TiktokSparkAdsDrawerDisplay
      isOpen={isOpen}
      size={Size.large}
      placement={placement}
      onRequestClose={onRequestClose}
      postUrl={postUrl}
    />
  );
}
