import manualMetricsValueGetter from 'components/Campaign/Influencer/View/Stats/Performance/columns/helpers/manualMetricValueGetter';
import { Placement } from 'shared/types/Placement';
import currencyColDef from 'components/DataGrid/Destination/colDef/currencyColDef';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/main';
import { getPostOrTotalFee } from 'components/Campaign/Influencer/View/Stats/Performance/columns/calculations/postFee';
import currency from 'components/DataGrid/formatters/currency';

export default function cpConv() {
  return {
    ...currencyColDef,
    valueFormatter: currency,
    valueGetter: ({ data, context }: ValueGetterParams<Placement>) => {
      if (!data) {
        return null;
      }

      const conversions = manualMetricsValueGetter(
        data,
        context,
        'conversions',
      );
      const cogs = getPostOrTotalFee(data);

      if (
        !conversions ||
        !cogs ||
        !Number.isFinite(Number(conversions)) ||
        !Number.isFinite(Number(cogs))
      ) {
        return null;
      }

      return Number(cogs) / Number(conversions);
    },
    editable: false,
    headerName: 'CP Conv',
    field: 'cpConv',
  };
}
