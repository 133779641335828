import TiktokOrderReportDrawer from 'components/Tiktok/TiktokOrderReportDrawer/TiktokOrderReportDrawer';
import TiktokSparkAdsDrawer from 'components/Tiktok/TiktokSparkAds/TiktokSparkAdsDrawer/TiktokSparkAdsDrawer';
import React from 'react';
import { TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

import './OrderReportToggle.scss';

export default function OrderReportToggle({
  placement,
}: {
  placement: Placement;
}) {
  const [showDrawer, setShowDrawer] = React.useState({
    orderReport: false,
    sparkAds: false,
  });

  const onOpenOrderReportDrawer = React.useCallback(() => {
    setShowDrawer({ orderReport: true, sparkAds: false });
  }, []);

  const onCloseOrderReportDrawer = React.useCallback(() => {
    setShowDrawer({ sparkAds: false, orderReport: false });
  }, []);

  const onOpenSparkAdsDrawer = React.useCallback(() => {
    setShowDrawer({ orderReport: false, sparkAds: true });
  }, []);

  const onCloseSparkAdsDrawer = React.useCallback(() => {
    setShowDrawer({ sparkAds: false, orderReport: true });
  }, []);

  const { platform, postUrl } = React.useMemo(
    () => ({
      platform: placement?.platform,
      postUrl: placement?.source?.url,
    }),
    [placement?.platform, placement?.source?.url],
  );

  if (platform !== TIKTOK_PLATFORM) {
    return null;
  }

  return (
    <div className="order-report-toggle">
      <button type="button" onClick={onOpenOrderReportDrawer}>
        Open order report
      </button>

      <TiktokOrderReportDrawer
        isOpen={showDrawer.orderReport}
        placement={placement}
        onRequestClose={onCloseOrderReportDrawer}
        onManageSparkAds={onOpenSparkAdsDrawer}
      />

      <TiktokSparkAdsDrawer
        isOpen={showDrawer.sparkAds}
        onRequestClose={onCloseSparkAdsDrawer}
        postUrl={postUrl}
        placement={placement}
      />
    </div>
  );
}
