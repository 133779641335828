import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Tippy from '@tippyjs/react';
import get from 'lodash/get';
import { useEffect, useRef, useState } from 'react';
import useUser from 'store/authentication/useUser';

import Messages from 'components/Chat/Messages/Messages';
import UIButton from 'components/UI/Button/Button';
import UIDialog from 'components/UI/Dialog/Dialog';
import useDialog from 'components/UI/Dialog/useDialog';
import UIIconButton from 'components/UI/IconButton/IconButton';
import UIIconChatCounter from 'components/UI/IconChatCounter/IconChatCounter';
import UIIconDoubleCounter from 'components/UI/IconDoubleCounter/IconDoubleCounter';
import useClickOutside from 'components/UI/useClickOutside';
import commentsIcon from 'components/assets/img/comment-icon.png';
import {
  createMessageMutation,
  deleteMessageMutation,
  messagesQuery,
  updateMessageMutation,
} from './graphql';
import { updateCacheOnCreate, updateCacheOnUpdate } from './utils';

import './Chat.scss';

const cbn = 'chat';

const hasPendingMessages = (editingMessages) =>
  Object.values(editingMessages).some(Boolean);

function onToggleEdit(setEditingMessages, id) {
  setEditingMessages((editingM) => ({
    ...editingM,
    [id]: !editingM[id],
  }));
}

const getActiveTab = (value) =>
  value === 'external' ? 'external' : 'internal';

/* eslint-disable-next-line max-statements */
function Chat({
  childKey = undefined,
  childType = undefined,
  counts = {},
  entityId = null,
  isExternal = false,
  loadOnMount = false,
  inline = false,
  entityType,
  onCreateMessage: parentOnCreateMessage,
}) {
  const { user } = useUser();
  const triggerRef = useRef();
  const userRole = isExternal ? 'external' : user?.role;

  const [inputValue, setInputValue] = useState('');
  const [isChatOpened, setIsChatOpened] = useState(false);
  const [editingMessages, setEditingMessages] = useState({});
  const [activeTab, setActiveTab] = useState(getActiveTab(userRole));

  const {
    isOpen: isDialogOpen,
    open: openDialog,
    close: closeDialog,
  } = useDialog();

  const [createLog] = useMutation(createMessageMutation);
  const [updateLog] = useMutation(updateMessageMutation);
  const [deleteLog] = useMutation(deleteMessageMutation);

  const [loadLogs, { data, loading, called }] = useLazyQuery(messagesQuery, {
    variables: {
      childKey,
      childType,
      entityId,
      entityType,
    },
  });

  useEffect(() => {
    if (loadOnMount === true && !loading && !called) {
      loadLogs();
    }
  }, [called, loadLogs, loadOnMount, loading]);

  const updateCachePayload = {
    childKey,
    childType,
    entityId,
    entityType,
  };

  function onCreateMessage() {
    if (!inputValue || !/\S/.test(inputValue)) return;

    const message = inputValue.replace(/\n\r?/g, '<br/>');

    const payload = {
      entityType,
      entityId,
      message,
      visibility: activeTab,
    };

    if (childType) payload.childType = childType;

    if (childKey) payload.childKey = childKey;

    createLog({
      variables: { payload },
      update: updateCacheOnCreate(updateCachePayload),
    });

    if (parentOnCreateMessage) {
      parentOnCreateMessage(payload);
    }
    setInputValue('');
  }

  function onUpdateMessage(_item, nInputValue, id) {
    updateLog({
      variables: {
        id,
        payload: {
          message: nInputValue,
        },
      },
      update: updateCacheOnUpdate(updateCachePayload),
    });

    onToggleEdit(setEditingMessages, id);
  }

  function onDeleteMessage(id) {
    deleteLog({
      variables: { id },
      update: updateCacheOnUpdate(updateCachePayload),
    });
  }

  function close() {
    closeDialog();
    setIsChatOpened(false);
    setEditingMessages({});
    setInputValue('');
  }

  function tryToClose(ev) {
    // outside click triggered from clicking on the toggle button, let onToggle handle it.
    if (ev && triggerRef.current && triggerRef.current.contains(ev.target)) {
      return;
    }

    if (!inputValue && !hasPendingMessages(editingMessages)) {
      close();
      return;
    }

    if (!isDialogOpen) {
      openDialog();
    }
  }

  function onToggle() {
    if (!isChatOpened && !loading && !called) {
      loadLogs();
    }

    return isChatOpened ? tryToClose() : setIsChatOpened(true);
  }

  const containerRef = useClickOutside(tryToClose, !isChatOpened);

  const meta = get(data, 'messages.meta') || counts || {};
  const logsdata = get(data, 'messages.data', []);

  return (
    <>
      <Tippy
        className={cbn}
        placement="right"
        theme="chat"
        arrow
        visible={isChatOpened}
        maxWidth="100%"
        interactive
        appendTo={document.body}
        content={
          <div ref={containerRef}>
            {isChatOpened && (
              <Messages
                data={logsdata}
                meta={meta}
                loading={loading}
                activeTab={activeTab}
                editingMessages={editingMessages}
                inputValue={inputValue}
                onDeleteMessage={onDeleteMessage}
                onInputChange={(e) => setInputValue(e.target.value)}
                onToggleEdit={(id) => onToggleEdit(setEditingMessages, id)}
                onUpdateMessage={onUpdateMessage}
                sendMessage={onCreateMessage}
                setActiveTab={setActiveTab}
                toggleTab={setActiveTab}
                user={user}
                userRole={userRole}
              />
            )}
          </div>
        }
      >
        <div ref={triggerRef} className={`${cbn}__icon-button`}>
          {isExternal ? (
            <UIIconChatCounter
              onClick={onToggle}
              otherCount={meta.external || 0}
            >
              <img
                alt="Comments"
                className="comments-icon"
                src={commentsIcon}
                onClick={onToggle}
              />
            </UIIconChatCounter>
          ) : (
            <UIIconDoubleCounter
              onClick={onToggle}
              otherCount={meta.external}
              count={!isExternal && meta.internal}
              inline={inline}
            >
              <UIIconButton icon="comments" onClick={onToggle} />
            </UIIconDoubleCounter>
          )}
        </div>
      </Tippy>

      <UIDialog
        title="Confirm close"
        description="You have unsent messages, are you sure you wish to close?"
        isOpen={isDialogOpen}
        onClose={closeDialog}
        overlayClassName={`${cbn}__overlay-chat`}
        renderActions={() => (
          <>
            <UIButton theme="text" onClick={closeDialog}>
              Keep the Chat
            </UIButton>
            <UIButton theme="contained-red" onClick={close}>
              Discard message and Close
            </UIButton>
          </>
        )}
      />
    </>
  );
}

export default Chat;
