import { History } from 'history';
import React, { useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';
import useLazyRest from 'store/api/useLazyRest';
import storage from 'utils/storage';

import { Analytics } from '@segment/analytics-next';
import { loadPostHog } from 'components/PostHog/PostHog';
import { loadSegment } from 'components/Segment/Segment';
import { Permissions, User } from 'components/User/types';
import { PostHog } from 'posthog-js';
import {
  ACCESS_TOKEN,
  ISB_TOKEN_KEY,
  ISB_USER,
  PERMISSIONS,
  USER,
  VERSIONING,
  setSentryUser,
} from 'store/authentication/session';
import {
  hasBrandAppAccess,
  hasInternalAppAccess,
} from 'store/authentication/utils';
import buildClient from 'store/graphql/apolloClient';
import { Environment } from 'types';
import Context, { Versioning, LoginSession } from './Context';
import useErrorHandlerProvider from './errorHandler/useErrorHandlerProvider';
import useStorage from './useStorage';
import { transformUser } from './utils';

import LoggedUserQuery from './LoggedUser';

import jsonVersioning from '../../constants/versioning.json';

type ApplicationProviderProps = WithChildren<{
  history: History;
  environment?: Environment;
}>;

const TIME_LIMIT = 60 * 60 * 1000; // one hour
/* eslint-disable-next-line max-statements, max-lines-per-function */
function ApplicationProvider({
  children,
  history,
  environment,
}: ApplicationProviderProps) {
  const location = history?.location;
  const [token, setToken] = useStorage<string>(ACCESS_TOKEN);
  const [user, setPersistentUser] = useStorage<User>(USER);
  const [isbToken, setIsbToken] = useStorage<string>(ISB_TOKEN_KEY);
  const [isbUser, setIsbUser] = useStorage(ISB_USER);
  const [versioning, setVersioning] = useStorage<Versioning>(VERSIONING);
  const [permissions, setPermissions] = useStorage<Permissions>(PERMISSIONS);
  const errorHandler = useErrorHandlerProvider();
  const [segment, setSegment] = useState<Analytics | null>(null);
  const [postHog, setPostHog] = useState<PostHog | null>(null);

  const apolloClient = useMemo(() => buildClient(token, permissions), [
    token,
    permissions,
  ]);

  const timeSinceLastUpdate = versioning
    ? Date.now() - versioning.lastUpdate
    : 0;

  useEffect(() => {
    if (!segment && environment) {
      loadSegment(environment, setSegment);
    }
  }, [environment, setSegment, segment]);

  useEffect(() => {
    if (!postHog && environment) {
      loadPostHog(environment, setPostHog);
    }
  }, [environment, setPostHog, postHog]);

  const setUser = React.useCallback(
    (u: User | null) => {
      setPersistentUser(transformUser(u));
      setSentryUser(u);
    },
    [setPersistentUser],
  );

  const [loadPermissions, { pending: permissionsIsLoading }] = useLazyRest({
    url: '/users/permissions',
    method: 'get',
    onCompleted: (res) => setPermissions(res),
  });

  const clearLocalStorage = React.useCallback(() => {
    setUser(null);
    setToken(null);
    setPermissions(null);
    storage.remove(USER);
    storage.remove(ACCESS_TOKEN);
    storage.remove(PERMISSIONS);
    storage.remove(VERSIONING);
  }, [setPermissions, setToken, setUser]);

  const [logout] = useLazyRest({
    url: '/users/logout',
    method: 'post',
    onCompleted: () => {
      clearLocalStorage();
      postHog?.stopSessionRecording();

      if (storage.get('$BEN$--microsoftSSO') === true) {
        const { pathname } = window.location;
        const redirect = pathname === '/team/user/login' ? 'team' : 'client';

        window.location.assign(
          `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}/${redirect}/user/login`,
        );
      }
    },
  });

  const loadUser = React.useCallback(async () => {
    if (!user?.flags?.HasAcceptedWebsiteTerms) {
      return;
    }

    try {
      const res = await apolloClient.query({
        query: LoggedUserQuery,
        variables: {
          userId: user.id,
        },
      });
      if (res.errors) {
        return;
      }
      const hasInternalAccess = hasInternalAppAccess(user);
      const hasBrandAccess = hasBrandAppAccess(user);

      if (!hasInternalAccess && !hasBrandAccess) {
        logout();
        return;
      }
      const loggedinUser: User = res.data.user;
      setUser(loggedinUser);

      const additionalData = {
        first_name: loggedinUser.firstName,
        last_name: loggedinUser.lastName,
        email: loggedinUser.email,
        team: loggedinUser.team,
        featureFlags: Object.entries(loggedinUser.featureFlags || {})
          .filter(([, flag]) => flag.status === 'optional')
          .reduce((acc, [name, flag]) => {
            const selection =
              typeof flag.userSelection === 'boolean'
                ? flag.userSelection
                : null;
            acc[name] = selection;
            return acc;
          }, {} as { [key: string]: boolean | null }),
      };

      postHog?.identify(res.data.user.id, additionalData);
      // start the session recording once the user has logged in
      if (environment === 'production') postHog?.startSessionRecording();
    } catch (e) {}
  }, [apolloClient, logout, setUser, user, postHog, environment]);

  function loadUserSession() {
    if (!user?.flags?.HasAcceptedWebsiteTerms) {
      return;
    }
    loadUser();
    loadPermissions();
  }

  const updateVersioning = React.useCallback(() => {
    setVersioning({
      uuid: jsonVersioning.uuid,
      lastUpdate: Date.now(),
    });
  }, [setVersioning]);

  const setSession = React.useCallback(
    (loginSession: LoginSession) => {
      errorHandler.reset();
      setUser(loginSession.user);
      setToken(loginSession.access_token);
      setPermissions(loginSession.permissions);
      updateVersioning();
    },
    [updateVersioning, errorHandler, setUser, setToken, setPermissions],
  );

  const passedLimit = timeSinceLastUpdate > TIME_LIMIT;
  useEffect(() => {
    if (!passedLimit && token && location?.pathname !== '/isb') {
      loadUserSession();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (passedLimit && token) {
      loadUserSession();
      updateVersioning();
      return;
    }

    if (versioning?.uuid !== jsonVersioning.uuid) {
      updateVersioning();
      if (token) {
        loadUserSession();
      }
      return;
    }
    if (token) {
      loadUserSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedLimit, token, versioning]);

  useEffect(() => {
    if (token && get(errorHandler.errors, '[0].raw.response.status') === 401) {
      clearLocalStorage();
    }
  }, [clearLocalStorage, errorHandler.errors, token]);

  const value = React.useMemo(
    () => ({
      apolloClient,
      errorHandler,
      isbToken,
      isbUser,
      logout,
      permissions,
      permissionsIsLoading,
      setIsbToken,
      setIsbUser,
      setPermissions,
      setSession,
      setToken,
      setUser: (u: User | null) => {
        updateVersioning();
        return setUser(u);
      },
      setVersioning,
      token,
      user,
      versioning,
      history,
      setSegment,
      segment,
      postHog,
      loadUser,
    }),
    [
      apolloClient,
      errorHandler,
      isbToken,
      isbUser,
      logout,
      permissions,
      permissionsIsLoading,
      setIsbToken,
      setIsbUser,
      setPermissions,
      setSession,
      setToken,
      updateVersioning,
      setUser,
      setVersioning,
      token,
      user,
      versioning,
      history,
      setSegment,
      segment,
      postHog,
      loadUser,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default ApplicationProvider;
