import ArrowCircleRight from '@UIComponents/_assets/react_svg/ArrowCircleRight';
import LinkButton from 'components/Creator/LinkButton/LinkButton';
import MediaStats from 'components/Creator/Productions/Card/MediaStats/MediaStats';
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Production } from 'shared/types/Production';

import './RecentVideos.scss';
import { TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import {
  TCMAuthorizedMediaInsights,
  TCMPublicMediaInsights,
} from 'shared/types/TikTokMarketPlace';

export default function RecentVideos({
  production,
}: {
  production: Production;
}) {
  const videos:
    | TCMAuthorizedMediaInsights[]
    | TCMPublicMediaInsights[] = React.useMemo(
    () =>
      (
        production?.tiktokCreatorMediaInsights?.authorizedMediaInsights
          ?.posts ??
        production?.tiktokCreatorMediaInsights?.publicMediaInsights?.posts ??
        []
      )
        .filter((video) => Boolean(video?.videoId))
        .slice(0, 8),
    [production],
  );

  if (!videos?.length && production?.url) {
    return (
      <a href={production?.url} rel="noreferrer" target="_blank">
        <LinkButton label="Open recent videos" solid />
      </a>
    );
  }
  return (
    <section className="tiktok-recent-videos">
      <h2>Recent Videos</h2>
      <section className="tiktok-recent-videos__videos-container">
        {videos.slice(0, 10).map((video) => (
          <div
            key={`tiktok_${video?.videoId}`}
            className="tiktok-recent-videos__videos-container__video"
          >
            <div className="video-container">
              <a href={video?.embedUrl} rel="noreferrer" target="_blank">
                <img src={video?.thumbnailUrl} alt="" />
              </a>
            </div>

            <p className="caption">{video?.caption}</p>
            <MediaStats
              platform={TIKTOK_PLATFORM}
              metrics={[
                {
                  label: 'Views',
                  value: video?.videoViews,
                  type: 'wholeNumber',
                },
                {
                  label: 'Likes',
                  value: video?.likes,
                  type: 'wholeNumber',
                },
                {
                  label: 'Comments',
                  value: video?.comments,
                  type: 'wholeNumber',
                },
              ]}
              createdDate={
                video?.createTime
                  ? new Date(Number(video.createTime)).toString()
                  : undefined
              }
            />
          </div>
        ))}
      </section>
      <div className="svg-container">
        <a href={`${production?.url}`} rel="noreferrer" target="_blank">
          <ArrowCircleRight />
        </a>
        <div className="filler" />
      </div>
    </section>
  );
}
