import React from 'react';
import formatDate from 'shared/dates/formatDate';
import { Property } from 'shared/types/Property';
import { TCMAuthorizedMediaInsights } from 'shared/types/TikTokMarketPlace';
import getThumbnailFromProductions from 'shared/utils/productions/getThumbnailFromProductions';
import Avatar from '../../../../Avatar/Avatar';
import LabelLink from '../../../../LabelLink/LabelLink';
import CopyIcon from '../../../../_assets/react_svg/CopyIcon';

import ClipboardCopyLabel from '../../../../ClipboardCopyLabel/ClipboardCopyLabel';

import './Header.scss';

export default function Header({
  property,
  currentPost,
}: {
  property?: Property;
  currentPost: TCMAuthorizedMediaInsights;
}) {
  const [imageError, setImageError] = React.useState(false);

  return (
    <section className="tiktok-video-content__header">
      <Avatar
        alt="avatar"
        name="avatar"
        size="medium"
        imageError={imageError}
        setImageError={setImageError}
        thumb={getThumbnailFromProductions(property?.productions ?? [])}
      />

      <div className="tiktok-video-content__header__info">
        <h1>Content ID #: {currentPost?.videoId}</h1>
        <LabelLink
          label={currentPost?.displayName ?? ''}
          href={`https://www.tiktok.com/@${currentPost?.displayName}`}
          linkType="external"
        />

        {currentPost?.videoId ? (
          <ClipboardCopyLabel
            copyValue={currentPost?.videoId}
            label={<CopyIcon size="x-small" stroke="#8F8F8F" />}
          />
        ) : null}
      </div>

      {currentPost?.createTime ? (
        <p className="tiktok-video-content__header__live-date">
          Live Date:{' '}
          {formatDate(
            new Date(Number(currentPost?.createTime) * 1000).toString(),
          )}
        </p>
      ) : null}
    </section>
  );
}
