import Drawer, { Size } from '@UIComponents/Drawer/Drawer';
import TiktokOrderReport from '@UIComponents/PlatformCards/Tiktok/OrderReport/OrderReport';
import { useQuery } from '@apollo/react-hooks';
import UILoader from 'components/UI/Loader/Loader';
import React from 'react';
import { getTikTokHandleNameFromUrl } from 'shared/regex/platformUrls';
import { Placement } from 'shared/types/Placement';

import TikTokOrder from '../graphql/TikTokOrder.graphql';
import { InitialData } from '../types';
import getDrawerData from './utils/getDrawerData';
import mergeData from './utils/mergeData';

function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);

  return date;
}

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export default function TiktokOrderReportDrawer({
  isOpen,
  placement,
  onRequestClose,
  onManageSparkAds,
}: {
  isOpen: boolean;
  placement: Placement;
  onRequestClose: () => void;
  onManageSparkAds: () => void;
}) {
  const [reportingWindowDates, setReportingWindowDates] = React.useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const initialData: InitialData | null = React.useMemo(
    () => getDrawerData(placement),
    [placement],
  );

  const tikTokOrderVariables = React.useMemo(
    () => ({
      username: getTikTokHandleNameFromUrl(placement?.source?.url),
      videoId: String(initialData?.videoId),
      deliverableId: initialData?.deliverableId,
      startDate: reportingWindowDates?.startDate,
      endDate: reportingWindowDates?.endDate,
    }),
    [
      initialData?.deliverableId,
      initialData?.videoId,
      placement?.source?.url,
      reportingWindowDates?.endDate,
      reportingWindowDates?.startDate,
    ],
  );

  const response = useQuery(TikTokOrder, {
    skip:
      !isOpen ||
      !tikTokOrderVariables?.username ||
      !tikTokOrderVariables?.videoId ||
      !tikTokOrderVariables?.deliverableId,
    variables: tikTokOrderVariables,
    fetchPolicy: 'cache-first',
  });

  const tikTokData = React.useMemo(
    () => mergeData(initialData ?? {}, response?.data),
    [initialData, response?.data],
  );

  const onReportingWindowChange = React.useCallback(
    (value: string) => {
      if (value === 'all_time') {
        setReportingWindowDates({ startDate: null, endDate: null });
        return;
      }

      const numDays = Number(value.split('_')?.[0]);
      const placementPostDate = placement?.source?.releaseDate;
      let startDate = null;
      let endDate = null;
      if (placementPostDate) {
        startDate = new Date(placementPostDate);
        endDate = addDaysToDate(new Date(placementPostDate), numDays);
      }

      if (!startDate || !endDate) {
        setReportingWindowDates({ startDate: null, endDate: null });
        return;
      }
      setReportingWindowDates({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
    },
    [placement?.source?.releaseDate],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Drawer
      isOpen={isOpen}
      size={Size.xlarge}
      onRequestClose={onRequestClose}
      fluid={false}
      overlay={false}
    >
      {response?.loading ? <UILoader /> : null}

      <TiktokOrderReport
        tikTokOrderReport={tikTokData?.deliverable?.tiktokOrderReport ?? {}}
        property={tikTokData?.deliverable?.property ?? {}}
        videoId={tikTokData?.videoId ?? ''}
        onManageSparkAds={onManageSparkAds}
        sparkAdStatusData={tikTokData?.tiktokVideoStatus ?? {}}
        onReportingWindowChange={onReportingWindowChange}
        tikTokCreatorMediaInsights={tikTokData?.tikTokCreatorMediaInsights}
      />
    </Drawer>
  );
}
