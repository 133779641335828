import classnames from 'classnames';

import './Group.scss';

function UIGridGroup({
  align = undefined,
  children = null,
  className = null,
  column = false,
  container = false,
  noLabel = false,
  noMargin = false,
  noPadding = false,
  noPaddingTop = false,
  pullRight = false,
  size = 'contained',
  style = {},
  dataCy = '',
}) {
  return (
    <div
      style={style}
      data-cy={dataCy}
      className={classnames('ui-grid-group', className, {
        [`ui-grid-group--${size}`]: size,
        'ui-grid-group--container': container,
        'ui-grid-group--pull-right': pullRight,
        'ui-grid-group--no-margin': noMargin,
        'ui-grid-group--no-padding': noPadding,
        'ui-grid-group--no-padding-top': noPaddingTop,
        'ui-grid-group--column': column,
        'ui-grid-group--no-label': noLabel,
        [`ui-grid-group--align-${align}`]: align,
      })}
    >
      {children}
    </div>
  );
}

export default UIGridGroup;
