import OrderReportToggle from 'components/DataGrid/Cells/TikTok/OrderReportToggle/OrderReportToggle';
import baseColDef from 'components/DataGrid/Destination/colDef/baseColDef';
import { TIKTOK_PLATFORM } from 'shared/metrics/metrics';
import { Placement } from 'shared/types/Placement';

export default function tikTokOrderReport() {
  return {
    ...baseColDef,
    field: 'tiktok_order_report',
    headerName: 'TikTok Order Report',
    cellRenderer: ({ data }: { data: Placement }) => {
      if (data?.platform === TIKTOK_PLATFORM && data?.source?.url) {
        return <OrderReportToggle placement={data} />;
      }

      return null;
    },
  };
}
