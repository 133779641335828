import './LinkCellRenderer.scss';

export default function LinkCellRenderer({
  value,
  dataCy,
  icon,
}: Readonly<{
  value: string;
  dataCy: string;
  icon?: string;
}>) {
  return (
    <div className="ag-cell-destination link" data-cy={dataCy}>
      {!!icon && <span className={`icon-${icon}`} />}
      <a rel="noreferrer" href={value} target="_blank">
        {value}
      </a>
    </div>
  );
}
