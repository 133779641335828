import React from 'react';
import BaseIcon from '../BaseIcon/BaseIcon';

import './Alert.scss';

const icons = {
  error: 'info_outline',
  warning: 'warning',
  info: 'info_outline',
  success: 'thumb_up_outline',
};

type AlertProps = {
  showIcon?: boolean;
  text: string;
  type: 'error' | 'warning' | 'info' | 'success';
  onClose?: () => void;
};

function Alert({
  showIcon = false,
  text,
  type,
  onClose,
}: Readonly<AlertProps>) {
  return (
    <div className={`Alert Alert--${type}`}>
      <div className="info-text">
        {showIcon ? <BaseIcon icon={icons[type]} size="small" /> : null}
        <p>{text}</p>
      </div>
      {!!onClose && (
        <button className="alert-close-button" onClick={onClose} type="button">
          <span className="icon-close" />
        </button>
      )}
    </div>
  );
}

export default Alert;
