import React from 'react';
import { Production, QmMetrics } from 'components/Production/types';
import Loader from 'components/UI/Loader/Loader';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import usePermissions from 'store/authentication/usePermissions';
import CardMetrics from './CardMetrics/CardMetrics';
import InstagramProductionQuery from './graphql/InstagramProductionQuery.graphql';
import RecentPosts from './RecentPosts/RecentPosts';
import './Instagram.scss';

const QM_INSTAGRAM_METRICS = 'ig2-productions';

export default function Instagram({
  production,
  apolloClient,
  showRecentMedia,
}: Readonly<{
  production: Production;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  showRecentMedia: boolean;
}>) {
  const [instagramQuery, setInstagramQuery] = React.useState<ApolloQueryResult<{
    production?: Production;
  }> | null>(null);
  const permissions = usePermissions('productions');

  React.useEffect(() => {
    async function load() {
      const result = await apolloClient.query({
        query: InstagramProductionQuery,
        variables: {
          id: production?.id,
          metricConfig: [
            {
              platform: 'instagram',
              reportingWindow: 'allTime',
            },
          ],
          productionsSeeSensitiveData: true,
        },
      });

      setInstagramQuery(result);
    }

    if (!apolloClient || !production?.id) {
      return;
    }

    load();
  }, [production?.id, apolloClient, permissions?.seeSensitiveData]);

  const platformMetrics = React.useMemo(() => {
    const qmMetrics: QmMetrics[] | undefined =
      (production?.qmMetrics as QmMetrics[]) ?? [];

    if (qmMetrics?.length) {
      return qmMetrics.find((p) => p.type === QM_INSTAGRAM_METRICS);
    }
    return null;
  }, [production?.qmMetrics]);

  const metricResults = React.useMemo(
    () => instagramQuery?.data?.production?.metricResults,
    [instagramQuery?.data?.production?.metricResults],
  );

  if (instagramQuery?.loading) {
    return <Loader />;
  }

  return (
    <section className="creator-instagram-details">
      {showRecentMedia ? <RecentPosts productionUrl={production?.url} /> : null}
      <CardMetrics metrics={{ platformMetrics, metricResults }} />
    </section>
  );
}
