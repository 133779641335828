import { Placement } from 'shared/types/Placement';
import numberColDef from 'components/DataGrid/Destination/colDef/numberColDef';

export default function shortioClicks() {
  return {
    ...numberColDef,
    field: 'shortioClicks',
    headerName: 'Short.io Clicks',
    valueGetter: ({ data }: { data: Placement }) =>
      data?.shortioMetrics?.clicks ?? null,
  };
}
