import {
  longDateFormat,
  longDateFormatWithTime,
} from 'components/utils/formats';
import moment from 'moment';

const getFormat = (time) => (time ? longDateFormatWithTime : longDateFormat);

export default function UIDate({
  as: Component = 'span',
  children = null,
  className = '',
  date: legacyProps = null,
  format: parentFormat = null,
  placeholder = 'N/A',
  time = false,
}) {
  const value = legacyProps || children;
  const format = parentFormat || getFormat(time);

  const display =
    value && moment(value).isValid()
      ? moment.utc(value).format(format)
      : placeholder;

  return <Component className={className}>{display}</Component>;
}
