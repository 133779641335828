import React from 'react';
import { ITooltipParams } from 'ag-grid-community';

import './CustomTooltip.scss';

function CustomTooltip(props: ITooltipParams) {
  return (
    <div className="ag-cell-destination custom-tooltip">{props.value}</div>
  );
}

export default CustomTooltip;
