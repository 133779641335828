import UIIcon from 'components/UI/Icon/Icon';
import './PageError.scss';

const cbn = 'ui-page-error';

function UIPageError({ children = 'Error', description = null }) {
  return (
    <div className={cbn}>
      <UIIcon type="warning" className={`${cbn}__icon`} />
      <div className={`${cbn}__info`}>
        <h1 className={`${cbn}__title`}>{children}</h1>
        {description && <p>{description}</p>}
      </div>
    </div>
  );
}

export default UIPageError;
