import { FileType } from 'components/Form/FileUploader/types';
import UIClipboardCopy from 'components/UI/ClipboardCopy/ClipboardCopy';
import UIIcon from 'components/UI/Icon/Icon';
import UIIconCounter from 'components/UI/IconCounter/IconCounter';
import React from 'react';
import { Placement } from 'shared/types/Placement';
import './PlacementMediaCount.scss';

export default function PlacementMediaCount({
  value,
  placement,
  openPlacementDrawer,
}: {
  value: string | null | undefined;
  placement: Placement;
  openPlacementDrawer: () => void;
}) {
  const mediaCount = React.useMemo(() => {
    const count: {
      videos: FileType[];
      images: FileType[];
      other: FileType[];
    } = {
      videos: [],
      images: [],
      other: [],
    };
    (placement?.statsScreenshots ?? []).forEach((file: FileType) => {
      if (!file?.mime) {
        count.other.push(file);
        return;
      }

      if (/^video/.test(file.mime)) {
        count.videos.push(file);
        return;
      }
      if (/^image/.test(file.mime)) {
        count.images.push(file);
        return;
      }
      count.other.push(file);
    });
    return count;
  }, [placement?.statsScreenshots]);

  if (!placement?.id) {
    return null;
  }

  return (
    <div className="placement-media-count-cell">
      <button onClick={() => openPlacementDrawer()} type="button">
        {!placement?.statsScreenshots?.length ? (
          <UIIcon icon="add" size="mid-small" />
        ) : (
          <UIIcon icon="visibility" size="mid-small" />
        )}
      </button>
      {value?.length ? (
        <span className="data-grid__cell__image-preview-copy">
          <UIClipboardCopy value={value} size="mid-small" />
        </span>
      ) : null}
      {mediaCount?.videos?.length ? (
        // @ts-expect-error to be refactored
        <UIIconCounter count={mediaCount.videos.length}>
          <UIIcon icon="videocam" size="mid-small" />
        </UIIconCounter>
      ) : null}

      {mediaCount?.images?.length ? (
        <span>
          {' '}
          {/* @ts-expect-error to be refactored */}
          <UIIconCounter count={mediaCount?.images?.length}>
            <UIIcon icon="photo_camera" size="mid-small" />
          </UIIconCounter>
        </span>
      ) : null}

      {mediaCount?.other?.length ? (
        <span>
          {' '}
          {/* @ts-expect-error to be refactored */}
          <UIIconCounter count={mediaCount?.other?.length}>
            <UIIcon icon="warning" size="mid-small" />
          </UIIconCounter>
        </span>
      ) : null}
    </div>
  );
}
