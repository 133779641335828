import { getDataCy } from 'components/Campaign/utils';
import { Placement } from 'shared/types/Placement';
import linkColDef from 'components/DataGrid/Destination/colDef/linkColDef';
import { Context } from 'components/Campaign/Influencer/View/shared/types';
import CustomTooltip from 'components/DataGrid/Destination/CustomTooltip/CustomTooltip';

const duplicateURL = ({
  data,
  context,
}: {
  data: Placement;
  context: Context;
}) => {
  const { duplicateUrls } = context;
  const { trackerUrl } = data;

  return duplicateUrls?.some((item) => item.trackerUrl === trackerUrl);
};

export default function trackingURL() {
  return {
    ...linkColDef,
    field: 'trackingURL',
    headerName: 'Tracking URL',
    valueGetter: ({ data }: { data: Placement }) => data?.trackerUrl,
    cellRendererParams: ({
      data,
      context,
    }: {
      data: Placement;
      context: Context;
    }) => {
      const duplicate = duplicateURL({ data, context });

      const icon = duplicate ? 'warning' : '';
      return {
        dataCy: getDataCy(data, 'trackerUrl'),
        icon,
      };
    },
    tooltipValueGetter: ({
      data,
      context,
    }: {
      data: Placement;
      context: Context;
    }) => {
      const duplicate = duplicateURL({ data, context });

      if (duplicate) return 'Duplicate tracking URL';

      return null;
    },
    filter: 'agTextColumnFilter',
    tooltipComponent: CustomTooltip,
  };
}
