import React from 'react';
import { mask } from 'utils/number';

const displayValue = (
  children?: number | null,
  placeholder?: string,
  maskopts?: {
    format?: string;
    convertFalsyToZero?: boolean;
    calculatePercentage?: boolean;

    leftPadding?: string | null;
    paddingChar?: string;
    placeholder?: string;
    prefix?: string | null;
    showZero?: boolean;
    suffix?: string | null;
  },
) => {
  if (children === null) {
    return placeholder;
  }

  return mask(children, maskopts);
};

type UINumberProps = {
  children?: number | null;
  className?: string;
  component?: React.ComponentType | string;
  format?: string;
  leftPadding?: string | null;
  paddingChar?: string;
  placeholder?: string;
  prefix?: string | null;
  showZero?: boolean;
  suffix?: string | null;
};

export default function UINumber({
  children = null,

  component: ComponentType = 'span',
  format = '0,0[.]000',
  placeholder = 'N/A',
  prefix = null,
  showZero = true,
  suffix = null,

  ...restProps
}: UINumberProps) {
  /**
   * We are passing invalid DOM elements in the spread props.
   * We should try to be explicit when we pass down props, not just randomly passing everything, as
   * that might cause unintended effects.
   */
  const componentProps = restProps ?? {};
  delete componentProps.leftPadding;
  delete componentProps.paddingChar;
  return (
    // @ts-expect-error component needs to be replaced
    <ComponentType {...componentProps}>
      {displayValue(children, placeholder, {
        format,
        prefix,
        suffix,
        showZero,
      })}
    </ComponentType>
  );
}
