/* eslint-disable max-statements */
import React, { useContext } from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import ApplicationContext from 'components/Application/Context';
import { getDefaultLocation as getInternalDefaultLocation } from 'utils/auth';
import { join } from 'utils/history';
import { getDefaultLocation as getExternalDefaultLocation } from 'external/utils/auth';
import {
  redirectTo,
  hasInternalAppAccess,
  hasBrandAppAccess,
} from 'store/authentication/utils';
import { Permissions, User } from 'components/User/types';
import storage from 'utils/storage';
import { isProd } from 'components/AppHeader/AppHeader';
import { PostHog } from 'posthog-js';
import { formatUserId } from 'components/Segment/utils';
import AbsoluteRedirect from '../Redirect/AbsoluteRedirect';
import { RoutePrivateComponentProps, RoutePrivateProps } from './types';

const getDefaultLocation = ({
  externalContext = false,
  user,
}: {
  user: User;
  externalContext?: boolean;
}) => {
  const preferExternalLocation = externalContext || !hasInternalAppAccess(user);

  return preferExternalLocation
    ? getExternalDefaultLocation(user)
    : getInternalDefaultLocation(user);
};

/* eslint-disable-next-line */
function getDefaultRoute(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  { location, match, ...props }: any,
  user: User | null,
  token: string | null,
  permissions: Permissions | null,
  postHog: PostHog | null,
  logout: () => void,
  Component: ((props: RoutePrivateComponentProps) => JSX.Element) | undefined,
  externalContext: boolean,
  grantAccess: ((permissions: Permissions) => boolean) | undefined,
  requiredPermission: string | undefined,
) {
  if (!location) {
    return null;
  }

  if (!user || !token || !permissions) {
    if (externalContext) {
      storage.set(
        '$BEN$--externalRedirectOnAuthenticated',
        `${location?.pathname}${location?.search ?? ''}`,
      );
    } else {
      storage.set(
        '$BEN$--redirectOnAuthenticated',
        `${location?.pathname}${location?.search ?? ''}`,
      );
    }

    return (
      <Navigate
        to="/user/login"
        state={{
          from: location,
          redirectOnAuthenticated: true,
        }}
      />
    );
  }

  if (!hasBrandAppAccess(user) && !hasInternalAppAccess(user)) {
    logout();
    return <Navigate to="/user/login" />;
  }

  const defaultLocation = getDefaultLocation({ user, externalContext });

  // check if user has product placement team
  if (user) {
    const hasProductPlacementTeam = user.team?.includes('product placement');

    const bypassPPTeamCheck =
      postHog?.isFeatureEnabled('allowPPUserEntryOnInfluencerApp') || false;

    // redirect to /pp-new-platform
    // if user is on product placement team and feature flag is not enabled
    // we wait for posthog to load before checking if we should redirect, as it can take a while
    if (
      postHog &&
      location.pathname !== '/pp-new-platform' &&
      hasProductPlacementTeam &&
      !bypassPPTeamCheck &&
      isProd()
    ) {
      postHog?.capture('pp:redirect-new-platform-screen', {
        userId: user?.id && formatUserId(user?.id),
        pageAttemptUrl: defaultLocation,
      });

      return <Navigate to="/pp-new-platform" replace />;
    }
  }

  const isOnBrandApp = externalContext;
  const isOnInternalApp = externalContext === false;

  if (
    user.role === 'external' &&
    isOnInternalApp &&
    hasInternalAppAccess(user) &&
    !hasBrandAppAccess(user)
  ) {
    return <Navigate to="/pp-new-platform-external" replace />;
  }

  const redirectLocation = redirectTo({
    defaultLocation,
    grantAccess,
    permissions,
    requiredPermission,
    token,
    user,
    externalContext,
  });

  if (redirectLocation && redirectLocation.pathname !== location.pathname) {
    const { replace, ...to } = redirectLocation;

    if (isOnInternalApp && !hasInternalAppAccess(user)) {
      return (
        <AbsoluteRedirect
          to={join('/client/', to.pathname)}
          replace={replace}
        />
      );
    }

    if (isOnBrandApp && !hasBrandAppAccess(user)) {
      return (
        <AbsoluteRedirect to={join('/team/', to.pathname)} replace={replace} />
      );
    }
    return <Navigate to={to} />;
  }

  if (Component) {
    return (
      <Component
        {...props}
        permissions={permissions}
        user={user}
        location={location}
        match={match}
      />
    );
  }

  return null;
}

export default function RoutePrivate({
  element: Component,
  externalContext = false,
  grantAccess = null,
  render = null,
  requiredPermission = null,
}: RoutePrivateProps) {
  const { match, ...props } = useParams();
  const location = useLocation();
  const {
    token,
    user,
    permissions,
    permissionsIsLoading,
    logout,
    postHog,
  } = useContext(ApplicationContext);
  if (!render && !Component) {
    return null;
  }
  if (permissionsIsLoading) {
    return null;
  }

  return getDefaultRoute(
    { location, match, ...props },
    user,
    token,
    permissions,
    postHog,
    logout,
    Component ?? undefined,
    externalContext,
    grantAccess ?? undefined,
    requiredPermission ?? undefined,
  );
}
