import Drawer from '@UIComponents/Drawer/Drawer';
import DrawerSize from '@UIComponents/_types/types';
import { OnRequestClose } from 'components/Tiktok/TiktokSparkAds/CreateSparkAd/types';
import TiktokSparkAds from 'components/Tiktok/TiktokSparkAds/TiktokSparkAds';
import useSparkAds from 'components/Tiktok/TiktokSparkAds/hooks/useSparkAds';
import { Placement } from 'shared/types/Placement';

import ResendRequestConfirmationModal from '../../ConfirmationModals/ResendRequestConfirmationModal/ResendRequestConfirmationModal';
import SendRequestConfirmationModal from '../../ConfirmationModals/SendRequestConfirmationModal/SendRequestConfirmationModal';

export default function TiktokSparkAdsDrawerDisplay({
  isOpen,
  size,
  placement,
  onRequestClose,
  postUrl,
}: {
  isOpen: boolean;
  size: DrawerSize;
  onRequestClose: OnRequestClose;
  postUrl: string | undefined | null;
  placement: Placement;
}) {
  const {
    tikTokSparkAdStatusData,
    hasSparkAd,
    hasError,
    onCreateAd,
    onCloseModal,
    loading,
    period,
    setPeriod,
    confirmationModalIsOpen,
    resendConfirmationModalIsOpen,
    setResendConfirmationModalIsOpen,
    tiktokCreatorData,
    videoId,
    tikTokSparkAdStatusRefetch,
  } = useSparkAds(
    postUrl,
    onRequestClose,
    placement?.source?.production?.property?.id,
  );

  return (
    <Drawer
      isOpen={isOpen}
      size={size}
      onRequestClose={onRequestClose}
      fluid={false}
      overlay={false}
    >
      <TiktokSparkAds
        period={period}
        setPeriod={setPeriod}
        loading={loading}
        hasSparkAd={hasSparkAd}
        hasError={hasError}
        sparkAdStatusData={tikTokSparkAdStatusData?.tiktokVideoStatus}
        placement={placement}
        onCreateAd={onCreateAd}
        onCloseModal={onCloseModal}
        postUrl={postUrl}
        videoId={videoId}
        tiktokCreatorData={tiktokCreatorData}
        tikTokSparkAdStatusRefetch={tikTokSparkAdStatusRefetch}
      />
      <SendRequestConfirmationModal
        isOpen={confirmationModalIsOpen}
        onRequestClose={onRequestClose}
      />
      <ResendRequestConfirmationModal
        isOpen={resendConfirmationModalIsOpen}
        onRequestClose={() => setResendConfirmationModalIsOpen(false)}
        sparkAdStatusData={tikTokSparkAdStatusData?.tiktokVideoStatus}
        resendConfirmationModal={() => {
          onCreateAd(true);
        }}
      />
    </Drawer>
  );
}
