import Avatar from '@UIComponents/Avatar/Avatar';
import LabelLink from '@UIComponents/LabelLink/LabelLink';
import LabelValueComboHighlighted from '@UIComponents/LabelValueComboHighlighted/LabelValueComboHighlighted';
import SectionHeader from '@UIComponents/SectionHeader/SectionHeader';
import React from 'react';

import './CreatorInfo.scss';

export default function CreatorInfo({
  thumbnail,
  creatorName,
  vendorId,
  handleName,
  drawer,
}: {
  thumbnail: string;
  creatorName: string;
  vendorId?: string;
  handleName?: string;
  drawer: 'order' | 'spark-ads';
}) {
  const [imageError, setImageError] = React.useState(false);

  return (
    <section className={`tiktok-${drawer}__creator-info`}>
      <Avatar
        alt="avatar"
        name="avatar"
        imageError={imageError}
        setImageError={setImageError}
        thumb={thumbnail}
        dynamic
      />

      <SectionHeader label={creatorName} />

      {drawer === 'order' ? (
        <LabelValueComboHighlighted label="Vendor ID:" value={vendorId ?? ''} />
      ) : (
        <LabelLink
          href={`https://www.tiktok.com/@${handleName}`}
          label={handleName ?? ''}
          linkType="external"
        />
      )}
    </section>
  );
}
