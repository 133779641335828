import classnames from 'classnames';

import './IconCounter.scss';

const cbn = 'ui-icon-counter';

export default function UIIconCounter({
  className = null,
  count = false,
  showZero = false,

  children,
}) {
  return (
    <span className={classnames(cbn, className)}>
      {children}
      {(count || showZero) && (
        <span className={`${cbn}__count`}>{count || 0}</span>
      )}
    </span>
  );
}
