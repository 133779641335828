import classnames from 'classnames';

import './Col.scss';

function UIGridCol({
  className = null,
  children = null,
  xs = null,
  sm = null,
  md = null,
  lg = null,
  xlg = null,
  xxlg = null,
  mtn = false,
  noMargin = false,
  noPadding = false,
}) {
  return (
    <div
      className={classnames('ui-grid-col', className, {
        [`ui-grid-col--xs-${xs}`]: xs,
        [`ui-grid-col--sm-${sm}`]: sm,
        [`ui-grid-col--md-${md}`]: md,
        [`ui-grid-col--lg-${lg}`]: lg,
        [`ui-grid-col--xlg-${xlg}`]: xlg,
        [`ui-grid-col--xxlg-${xlg}`]: xxlg,
        'ui-grid-col--mtn': mtn,
        'ui-grid-col--no-margin': noMargin,
        'ui-grid-col--no-padding': noPadding,
      })}
    >
      {children}
    </div>
  );
}

export default UIGridCol;
