/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  Cell,
  Row,
  getGroupedRowModel,
  GroupingState,
  ExpandedState,
  getExpandedRowModel,
  Table,
  RowPinningState,
} from '@tanstack/react-table';
import { formatDate, formatFloatNumber } from '@UIComponents/utils/formatters';
import './ViewershipReactTable.scss';
import { Viewership } from 'services/watchApi';
import UIIcon from 'components/UI/Icon/Icon';

export interface ExtendedViewership extends Viewership {
  readonly label?: string;
  readonly isPrimaryViewership?: boolean;
  readonly isSecondaryViewership?: boolean;
  readonly hasSecondaryLabel?: boolean;
  readonly info?: React.ReactNode;
  readonly rowIndex?: number;
  readonly isPinnedBottom?: boolean;
  readonly isPinnedTop?: boolean;
}
function toKebabCase(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Insert a hyphen between lowercase and uppercase letters
    .toLowerCase(); // Convert the entire string to lowercase
}

type ViewershipTableProps = {
  readonly data: ExtendedViewership[];
  readonly columnDefs?: ColumnDef<ExtendedViewership, unknown>[];
  readonly onSortingChange?: (sorting: SortingState) => void;
  readonly onGridReady?: (table: Table<ExtendedViewership>) => void;
  readonly onCellClick?: (
    event: React.MouseEvent<HTMLTableCellElement>,
    cell: Cell<ExtendedViewership, unknown>,
    row: Row<ExtendedViewership>,
  ) => void;
  readonly topRowData?: ExtendedViewership[];
  readonly bottomRowData?: Partial<ExtendedViewership>[];
};

const columnHelper = createColumnHelper<ExtendedViewership>();

const defaultColumns = [
  columnHelper.accessor('station.displayName', {
    id: 'station',
    header: 'Station',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('premiereDate', {
    header: 'Air Date',
    cell: (info) => formatDate(info.getValue()),
  }),
  columnHelper.accessor('premiereImpressions', {
    header: 'Premiere',
    cell: (info) => formatFloatNumber(info.getValue() || 0),
  }),
  columnHelper.accessor('past30Impressions', {
    header: '30 days',
    cell: (info) => formatFloatNumber(info.getValue() || 0),
  }),
];
const getRowlabel = (row: ExtendedViewership) => {
  if (row.isPrimaryViewership) {
    return 'Primary';
  }
  if (row.hasSecondaryLabel) {
    return 'Secondary';
  }
  return '';
};
const clickableColumns = [
  'premiereImpressions',
  'past30Impressions',
  'past60Impressions',
  'past90Impressions',
  'past365Impressions',
];
const getBottomRow = (
  index: number,
  row: Partial<ExtendedViewership>,
): ExtendedViewership => ({
  id: `bottom-row-${index}`,
  type: '',
  notes: '',
  premiereDate: '',
  provider: '',
  premiereImpressions: 0,
  past30Impressions: 0,
  past60Impressions: 0,
  past90Impressions: 0,
  past365Impressions: 0,
  station: {
    id: 0,
    displayName: '',
    country: '',
    image: '',
  },
  watchStation: {
    id: 0,
    displayName: '',
    country: '',
    image: '',
  },
  premiereDetails: null,
  past30Details: null,
  past60Details: null,
  past90Details: null,
  past365Details: null,
  isPinnedBottom: true,
  ...row,
});

export function ViewershipReactTable({
  data,
  columnDefs,
  onSortingChange,
  onGridReady,
  onCellClick,
  topRowData = [],
  bottomRowData = [],
}: ViewershipTableProps) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [grouping] = React.useState<GroupingState>(['viewershipType']);
  const [expanded, setExpanded] = React.useState<ExpandedState>(true);

  const columns = React.useMemo(
    () => [
      {
        id: 'viewershipType',
        header: '',
        accessorFn: (row: ExtendedViewership) => getRowlabel(row),
      },
      ...(columnDefs ?? defaultColumns).map((col) => ({
        ...col,
      })),
    ],
    [columnDefs],
  );

  const allData = React.useMemo(() => {
    const processedData = data.map((row, index, arr) => {
      // If it's a secondary row, only the first one should have hasSecondaryLabel true
      if (row.isSecondaryViewership) {
        // Find the first secondary row in this group
        const isFirstSecondaryInGroup = !arr[index - 1]?.isSecondaryViewership;
        return {
          ...row,
          hasSecondaryLabel: isFirstSecondaryInGroup,
        };
      }
      return row;
    });

    return [
      ...(topRowData?.map((row) => ({ ...row, isPinnedTop: true })) ?? []),
      ...processedData,
      ...(bottomRowData?.map((row, index) => getBottomRow(index, row)) ?? []),
    ];
  }, [data, topRowData, bottomRowData]);

  // Set up row pinning state
  const rowPinning = React.useMemo<RowPinningState>(
    () => ({
      bottom: allData.filter((row) => row.isPinnedBottom).map((row) => row.id),
    }),
    [allData],
  );

  const table = useReactTable({
    data: allData,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      rowPinning,
    },
    onSortingChange: (updatedSorting) => {
      const newSorting =
        updatedSorting instanceof Function
          ? updatedSorting(sorting)
          : updatedSorting;

      setSorting(newSorting);
      if (onSortingChange) {
        onSortingChange(newSorting);
      }
    },
    enableRowPinning: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    enableGrouping: true,
    enableExpanding: true,
    sortingFns: {
      custom: (rowA, rowB, columnId) => {
        // Don't sort pinned rows - keep them at bottom
        if (rowA.original.isPinnedBottom) return 1;
        if (rowB.original.isPinnedBottom) return -1;

        const a = rowA.original;
        const b = rowB.original;

        // If one is secondary and other isn't, non-secondary comes first
        if (a.isSecondaryViewership !== b.isSecondaryViewership) {
          return a.isSecondaryViewership ? 1 : -1;
        }

        // For all other cases, use default column sorting
        const aValue: string | number | Date = rowA.getValue(columnId);
        const bValue: string | number | Date = rowB.getValue(columnId);
        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
        return 0;
      },
    },
  });

  React.useEffect(
    () => {
      if (onGridReady) {
        onGridReady(table);
      }
    },
    [] /* eslint-disable-line react-hooks/exhaustive-deps */,
  );

  const handleCellClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    cell: Cell<ExtendedViewership, unknown>,
    row: Row<ExtendedViewership>,
  ) => {
    if (onCellClick) {
      onCellClick(event, cell, row);
    }
  };

  const getRowClassName = (row: Row<ExtendedViewership>) => {
    const classNames = ['viewership-row'];
    if (row.original.isPinnedTop) classNames.push('pinned-row top-row');
    if (row.original.isPinnedBottom) classNames.push('pinned-row bottom-row');
    if (row.original.isPrimaryViewership) classNames.push('primary-row');
    if (row.original.isSecondaryViewership) classNames.push('secondary-row');
    if (row.original.hasSecondaryLabel) classNames.push('secondary-row-first');
    return classNames.join(' ');
  };
  const getIconSort = (sort: string | boolean) => {
    let iconName = '';
    if (sort === 'asc') {
      iconName = 'arrow_drop_up';
    } else if (sort === 'desc') {
      iconName = 'arrow_drop_down';
    }
    if (iconName === '') return null;
    return <UIIcon icon={iconName} />;
  };
  const getCellClassName = (cell: Cell<ExtendedViewership, unknown>) => {
    const classNames = ['viewership-cell'];
    if (cell.column.id === 'label') classNames.push('label-cell');
    const isClickable = cell.row.original.isPinnedBottom
      ? cell.column.id === 'past365Impressions'
      : clickableColumns.includes(cell.column.id);
    if (isClickable) classNames.push('clickable-cell');
    classNames.push(toKebabCase(cell.column.id));
    return classNames.join(' ');
  };
  const getCellContent = (subRow: Row<ExtendedViewership>) =>
    subRow.getVisibleCells().map((cell) => (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <td
        key={cell.id}
        data-cell-id={`${cell.id}-subrow-${subRow.id}`}
        onClick={(event) => handleCellClick(event, cell, subRow)}
        className={getCellClassName(cell)}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    ));

  return (
    <div className="viewership-table">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ cursor: 'pointer' }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {getIconSort(header.column.getIsSorted())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getGroupedRowModel().rows.map((row) => {
            if (!row.subRows.length) {
              return null;
            }

            return row.subRows.map((subRow) => (
              <tr key={subRow.id} className={getRowClassName(subRow)}>
                {getCellContent(subRow)}
              </tr>
            ));
          })}
        </tbody>
      </table>
    </div>
  );
}
