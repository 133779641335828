import UILoader from 'components/UI/Loader/Loader';
import { Deliverable } from 'shared/types/Deliverable';

function Pagination({
  cbn = '',
  loading = false,
  selected = [],
  renderPaginationActions = () => null,
}: {
  cbn: string;
  loading: boolean;
  selected: Array<{ data: Deliverable }>;
  renderPaginationActions: (arg0: {
    selected: Deliverable[];
    onDelete?: () => void;
  }) => React.ReactElement | null;
}) {
  return (
    <div className={`${cbn}__pagination`}>
      <div className={`${cbn}__pagination__loader`}>
        {loading ? <UILoader size="small" /> : null}
      </div>
      <nav className={`${cbn}__pagination__actions`}>
        {renderPaginationActions({
          selected: selected.map((s) => s.data) || [],
        })}
      </nav>
    </div>
  );
}

export default Pagination;
